import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";

import http from "../../../services/httpServices";

import Container from "../../../components/ui/Container";
import LoadingIcon from "../../../components/ui/LoadingIcon";
import SideTitle from "../../../components/ui/SideTitle";
import CourseCard from "../../../components/ui/CourseCard";
import auth from "../../../services/authServices";
import { ReactComponent as WaterMelonHeaderIcon } from "../../../assets/khaled-imagery/HeaderWaterMelonIcon.svg";

const Categorie = ({
    titleFirst = false,
    titleLast,
    api,
    className = "",
    grid = "normal",
    noCoursesPlaceholder = "سيتم اضافة الكورسات قريبًا",
    coursesList = false,
    isPrepaid = false,
    onPrepaidClick = null,
    isToAssignCourses = null,
    onAssignCourseClick = false,
}) => {
    const [courses, setCourses] = useState([]);

    if (!className.includes("space-x")) {
        if (grid === "normal") {
            className += ` grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-10`;
        } else {
            className += ` grid-cols-1 lg:grid-cols-2 gap-5`;
        }
    }

    const [placeholder, setPlaceholder] = useState(
        <div className="font-h2 col-span-1 md:col-span-2 lg:col-span-3 py-20">
            <span className="flex-center-both space-x-3 space-x-reverse">
                <LoadingIcon className={"font-h1 text-teal-600 dark:text-teal-400"} />
                <span>يتم الآن تحميل الكورسات</span>
            </span>
        </div>
    );
    const getCourses = async () => {
        try {
            const token = auth.getToken();
            const config = auth.getAuthConfig(token);
            const { data } = await http.get(api, config);

            setCourses([]);
            setCourses(data);
            setPlaceholder(
                <div className="font-h2 col-span-1 md:col-span-2 lg:col-span-3 py-20">
                    <span className="flex-center-both space-x-3 space-x-reverse">
                        <span className="font-h1 flex-center-both text-rose-600 dark:text-rose-400">
                            <Icon icon="carbon:data-error" />
                        </span>
                        <span>{noCoursesPlaceholder}</span>
                    </span>
                </div>
            );
        } catch (error) {
            setPlaceholder(
                <div className="font-h2 col-span-1 md:col-span-2 lg:col-span-3 py-20">
                    <span className="flex-center-both space-x-3 space-x-reverse">
                        <span className="font-h1 flex-center-both text-rose-600 dark:text-rose-400">
                            <Icon icon="carbon:data-error" />
                        </span>
                        <span>{noCoursesPlaceholder}</span>
                    </span>
                </div>
            );
        }
    };

    useEffect(() => {
        if (coursesList) {
            setCourses(coursesList);
            if (coursesList.length < 1) {
                setPlaceholder(
                    <div className="font-h2 col-span-1 md:col-span-2 lg:col-span-3 py-20">
                        <span className="flex-center-both space-x-3 space-x-reverse">
                            <span className="font-h1 flex-center-both text-rose-600 dark:text-rose-400">
                                <Icon icon="carbon:data-error" />
                            </span>
                            <span>{noCoursesPlaceholder}</span>
                        </span>
                    </div>
                );
            }
        } else {
            getCourses();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coursesList]);
    return (
        <Container>
            {/* {titleFirst ? <SideTitle first={titleFirst} last={titleLast} /> : ""} */}
            {titleFirst ? (
                <div className="py-5 z-10 relative flex-center-both md:flex-row flex-col">
                    <WaterMelonHeaderIcon className="relative z-10" />
                    <div className="flex flex-col font-rady">
                        <p className="text-stroke-transparent relative -z-10 -mr-5 font-w-bold text-3xl sm:text-5xl text-transparent">
                            {titleFirst + " " + titleLast}
                        </p>
                        <h1 className="text-3xl sm:text-5xl font-w-bold font-rady">
                            {titleFirst}
                            <span className="text-redMelon-500"> {titleLast} </span>
                        </h1>
                    </div>
                </div>
            ) : (
                ""
            )}

            <div
                className={`g-teal-400 smooth clr-text-primary drk:bg-teal-800 bg-opacity-50 dark:bg-opacity-50 grid ${className}`}
            >
                {courses.length < 1 && placeholder}
                {courses.length > 0 &&
                    courses.map((course) => {
                        return (
                            <CourseCard
                                key={course.id}
                                {...course}
                                isToAssignCourses={isToAssignCourses}
                                onAssignCourseClick={onAssignCourseClick}
                                isPrepaid={isPrepaid}
                                onPrepaidClick={onPrepaidClick}
                            />
                        );
                    })}
            </div>
        </Container>
    );
};

export default Categorie;

import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../context/AuthContext";
import auth from "../services/authServices";
import http from "../services/httpServices";
import { useLocation, useNavigate } from "react-router-dom";
import localStorageService from "../services/localStorageService";
import HasPrepaid from "./HasPrepaid";

import { isObjectEmpty } from "../utils/objects";
import {
    isManuallyDisablingAllExams,
    isManuallyDisablingAllVideos,
    isManuallyDisablingGlobalNotifications,
    isManullayDisablingNotifications,
    isManullayDisablingPrepaidStore,
    isManullayDisablingUnassignedSubscriptions,
    isPrepaidSystem,
    isUnassignedSubscription,
    prepaidInfoColor,
} from "../services/defaultSettings";
import Button from "../components/ui/Button";
import { printUnit } from "../utils/ar";
import GlobalNotifications from "../layout/navbar/components/GlobalNotifications";
import HasUnassignedSubscription from "./HasUnassignedSubscription";

const FetchAllUserData = ({ renderOnlyRefetchBtn = false }) => {
    const navigate = useNavigate();
    const location = useLocation();
    let {
        setPrepaidCourses,
        setUnassignedSubscriptions,
        setIsPrepaidChecked,
        prepaidCourses,
        isPrepaidChecked,
        setIsUnassignedSubscriptionsChecked,
        isUnassignedSubscriptionsChecked,
        unassignedSubscriptions,
        user,
        token,
    } = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});
    const [className, setClassName] = useState("");
    const [notifications, setNotifications] = useState([]);

    const authUser = auth.getUser() || {};
    let cachedPrepaidCoursesKey;
    let cachedUnassignedSubscriptionsKey;
    let cachedGlobalNotificationsKey;
    if (!isObjectEmpty(authUser) && authUser.email) {
        cachedPrepaidCoursesKey = `prepaidCourses_${authUser?.email}`;
        cachedUnassignedSubscriptionsKey = `unassignedSubscriptions_${authUser?.email}`;
        cachedGlobalNotificationsKey = `globalNotifications-${authUser?.email}`;
    }
    const fetchAllData = async () => {
        try {
            setIsLoading(true);
            const token = auth.getToken();
            const config = auth.getAuthConfig(token);
            const { data } = await http.get(`/api/user/fetch_related_data`, config);

            const { prepaid_courses, unassigned_subscriptions_count, global_notifications } = data;
            setNotifications(global_notifications);

            setPrepaidCourses(prepaid_courses);
            setIsPrepaidChecked(true);

            setUnassignedSubscriptions(unassigned_subscriptions_count);
            setIsUnassignedSubscriptionsChecked(true);
            if (!isObjectEmpty(authUser) && authUser.email) {
                localStorageService.set(cachedPrepaidCoursesKey, prepaid_courses, 1800000);
                localStorageService.set(
                    cachedUnassignedSubscriptionsKey,
                    unassigned_subscriptions_count,
                    1800000
                );
                localStorageService.set(
                    cachedGlobalNotificationsKey,
                    global_notifications,
                    3600000
                );
            }
            setNotifications(global_notifications);
            setData(data);
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false);
        }
    };

    const hasUnassignedSubscriptions = async () => {
        let unassigned_subscriptions;
        const cachedUnassignedSubscriptions = localStorageService.get(
            cachedUnassignedSubscriptionsKey
        );

        if (cachedUnassignedSubscriptions !== null) {
            unassigned_subscriptions = cachedUnassignedSubscriptions;
            setUnassignedSubscriptions(unassigned_subscriptions);
            setIsUnassignedSubscriptionsChecked(true);
        } else {
            if (!isUnassignedSubscriptionsChecked) {
                await fetchAllData();
            }
        }
        if (unassigned_subscriptions > 0) {
            const isSeen = auth.getIsavailableCoursesToAssignStoreSeen();
            if (isSeen) {
                return;
            }
            if (!location.pathname.includes("login")) {
                navigate("/available_to_assign_courses_store");
            }
        }
    };

    const hasPrepaid = async () => {
        let prepaid_courses;
        const cachedPrepaidCourses = localStorageService.get(cachedPrepaidCoursesKey);
        if (prepaidCourses > 0 && cachedPrepaidCourses !== null) {
            prepaid_courses = prepaidCourses;
        } else if (cachedPrepaidCourses !== null) {
            prepaid_courses = cachedPrepaidCourses;
            setPrepaidCourses(prepaid_courses);
            setIsPrepaidChecked(true);
        } else {
            if (!isPrepaidChecked) {
                await fetchAllData();
            }
        }
        if (prepaid_courses > 0) {
            const isSeen = auth.getIsPrepaidCoursesStoreSeen();
            if (isSeen || (unassignedSubscriptions > 0 && isUnassignedSubscription)) {
                return;
            }
            if (!location.pathname.includes("login")) {
                navigate("/prepaid_store");
            }
        }
    };

    useEffect(() => {
        const token = auth.getToken();
        if (token) {
            !isManullayDisablingUnassignedSubscriptions && hasUnassignedSubscriptions();
            !isManullayDisablingPrepaidStore && hasPrepaid();
        } else {
            setUnassignedSubscriptions(0);
            setPrepaidCourses(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, user.fullname, location, isUnassignedSubscriptionsChecked]);
    useEffect(() => {
        if (token) {
            const cachedGlobalNotifications =
                cachedGlobalNotificationsKey &&
                localStorageService.get(cachedGlobalNotificationsKey);
            if (cachedGlobalNotifications !== null) {
                setNotifications(cachedGlobalNotifications);
            } else {
                if (!isManuallyDisablingAllVideos && !isManuallyDisablingAllExams) {
                    fetchAllData();
                } else {
                    setNotifications([
                        {
                            name: "تنبييييه هاااااام",
                            description:
                                "بنعتذرلك جدًا جدًا جدًا على البطئ اللي ممكن تلاقيه في المنصه دلوقتي بس احنا شغالين ب أقصى مجهودنا اننا نخليلك المنصه اسرع ما يمكن في اسرع وقت ان شاء الله , احنا عارفين انك نفسك تذاكر دلوقتي بس بنستأذنك لو المنصه مش شغاله معاك دلوقتي ممكن تقفل المنصه و تحاول تفتح تاني بعد ساعة ان شاء الله",
                        },
                    ]);
                }
            }
        }
    }, [token]);
    useEffect(() => {
        if (location.pathname.includes("/admin")) {
            setClassName(" !hidden");
        } else {
            setClassName("");
        }
    }, [location.pathname]);

    const checkBalance = async () => {
        setIsLoading(true);
        localStorageService.remove(cachedPrepaidCoursesKey);
        localStorageService.remove(cachedUnassignedSubscriptionsKey);
        await fetchAllData();

        setIsLoading(false);
    };

    let bgColorClassName = "bg-rose-500";
    let valueBgColorClassName = "bg-blue-500";
    switch (prepaidInfoColor) {
        case "yellow":
            bgColorClassName = "bg-yellow-500";
            break;
        case "cyan":
            bgColorClassName = "bg-cyan-500";
            break;
        case "emerald":
            bgColorClassName = "bg-emerald-500";
            break;
        case "blue":
            bgColorClassName = "bg-blue-500";
            break;
        default:
            break;
    }

    return (
        <>
            {!renderOnlyRefetchBtn && (
                <>
                    {!isManuallyDisablingGlobalNotifications && (
                        <GlobalNotifications notifications={notifications} />
                    )}
                    {isPrepaidSystem && !isManullayDisablingPrepaidStore ? (
                        <HasPrepaid
                            renderOnlyRefetchBtn={renderOnlyRefetchBtn}
                            prepaidCourses={data.prepaid_courses}
                        />
                    ) : (
                        ""
                    )}
                    {isUnassignedSubscription && !isManullayDisablingUnassignedSubscriptions ? (
                        <HasUnassignedSubscription
                            renderOnlyRefetchBtn={renderOnlyRefetchBtn}
                            unassignedSubscriptions={data.unassigned_subscriptions_count}
                        />
                    ) : (
                        ""
                    )}
                </>
            )}
            {renderOnlyRefetchBtn &&
            (!isManullayDisablingPrepaidStore || !isManullayDisablingUnassignedSubscriptions) ? (
                <Button
                    onClick={checkBalance}
                    className={`${
                        prepaidCourses > 0
                            ? "bg-blue-100 dark:bg-primSky-200 smooth p-1"
                            : "bg-blue-500 text-white p-2"
                    } rounded-md border-none shadow-lg`}
                    isLoading={isLoading}
                >
                    {prepaidCourses > 0 || unassignedSubscriptions > 0 ? (
                        <div className="flex-center-both flex-col space-y-3">
                            <div className="clr-text-primary">لديك من الرصيد</div>
                            {unassignedSubscriptions > 0 && isUnassignedSubscription && (
                                <div
                                    className={`flex-center-both bg-slate-500 text-yellow-200 rounded-md font-w-bold shadow-lg font-h3 underline px-3 py-1`}
                                >
                                    {printUnit(unassignedSubscriptions, "اشتراك غير مخصص")}
                                </div>
                            )}
                            {prepaidCourses > 0 && (
                                <>
                                    <div
                                        className={`flex-center-both text-yellow-200 ${valueBgColorClassName} rounded-md font-w-bold shadow-lg font-h3 underline px-3 py-1`}
                                    >
                                        {" "}
                                        <span>{printUnit(prepaidCourses, "محاضرة")}</span>
                                        <br />
                                        <div>مسبقة الدفع</div>
                                    </div>
                                </>
                            )}
                            <div className="py-1 clr-text-primary px-3 w-full md:w-3/4 mx-auto rounded-md bg-rose-300 dark:bg-rose-500 smooth ">
                                دوس هنا في حالة شحن كود للإستعلام عن الرصيد
                            </div>
                        </div>
                    ) : (
                        <span> استعلام من هنا عن الرصيد الخاص بك بعد شحن الكود</span>
                    )}
                </Button>
            ) : (
                ""
            )}
        </>
    );
};

export default FetchAllUserData;

import { Icon } from "@iconify/react";
import React, { useState } from "react";

import { baseURL } from "../../config";
import { printFullDate } from "../../utils/time";
import { Link } from "react-router-dom";
// import Button from "./Button";
import { description as formatDescription } from "../../utils/ar";
import Button from "./Button";
import LoadingIcon from "./LoadingIcon";
import { ReactComponent as BreakLine } from "../../assets/khaled-imagery/breakline.svg";
import { ReactComponent as SingleBreakline } from "../../assets/khaled-imagery/singleBreakline.svg";
const CourseCard = ({
    name = "الكورس الاول - تيست",
    description = "جميع محتويات الكورس الاول",
    yearDescription = [],
    price = "30",
    isNew = false,
    picture,
    isStaticPicture = false,
    updated_at,
    created_at,
    subscriptions_count = 0,
    isYear = false,
    id,
    mode,
    isPrepaid = false,
    onPrepaidClick = null,
    isToAssignCourses = false,
    onAssignCourseClick = null,
    nickName = "",
    ...props
}) => {
    const [isLoading, setIsLoading] = useState(true);
    return (
        <div className="group ">
            {isYear ? (
                <Link to={`/years/${isYear}`}>
                    {/* <div className="w-[80%] bg-star box-shadow-yearCard relative z-10 sm:max-w-[385px] mx-auto rounded-xl p-6 bg-[#DBDBDB] dark:bg-sakrBlue-800 smooth"> */}
                    <div className="w-full sm:max-w-[385px] relative z-10 mx-auto flex-center-both overflow-y-visible rounded-xl ">
                        <img
                            src={isStaticPicture}
                            alt="YearCard"
                            className={`w-[90%] transform text-center group-hover:scale-110 group-hover:-translate-y-5 group-hover:brightness-110 group-hover:saturate-150 smooth`}
                        />
                    </div>
                    {/* </div> */}
                </Link>
            ) : (
                <div className="rounded-md overflow-hidden w-full">
                    <img
                        src={isStaticPicture ? isStaticPicture : `${baseURL}/${picture}`}
                        alt="course"
                        onLoad={() => {
                            setIsLoading(false);
                        }}
                        className={` ${
                            isLoading ? "hidden " : undefined
                        }w-full transform text-center group-hover:scale-110 group-hover:brightness-110 group-hover:saturate-150 smooth`}
                    />
                    {isLoading ? (
                        <div className="h-72 bg-slate-300 dark:bg-slate-50/10 flex-center-both font-h1 font-w-bold">
                            <div className="flex flex-row space-x-3 space-x-reverse">
                                <LoadingIcon className={"font-big text-blue-500"} />
                            </div>
                        </div>
                    ) : null}
                </div>
            )}

            <div className="px-5 -mt-14 relative z-0">
                {isYear && (
                    <Link to={`/years/${isYear}`}>
                        <div className="bg-[#F7F7F7] bg-star box-shadow-yearCard w-full sm:min-w-[90%] md:min-w-[300px] min-h-[320px]  flex-center-both rounded-md border border-black  dark:bg-sakrBlue-950 smooth">
                            <div className="flex flex-col space-y-6">
                                <div className="flex flex-col space-y-14 w-full">
                                    <h1 className="font-w-bold clr-text-primary smooth  font-rady text-3xl md:text-4xl text-center">
                                        {name} <span className="text-redMelon-500">{nickName}</span>
                                    </h1>

                                    <h3 className=" font-rady clr-text-primary smooth text-xl sm:text-2xl text-center">
                                        {yearDescription[0]}
                                        <br />
                                        {yearDescription[1]}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </Link>
                )}
                {!isYear && (
                    <div
                        className={`rounded-md w-full bg-third-container clr-text-primary px-4 py-6 shadow-large--oblique hover-shadow-larg group-hover:shadow-large smooth ${
                            subscriptions_count < 1
                                ? "border border-slate-300 dark:border-slate-800"
                                : "border-2 border-cyan-500"
                        }`}
                    >
                        <div className="flex flex-col space-y-6 ">
                            <div className="flex flex-center-y justify-between flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 sm:space-x-reverse">
                                <div className="flex flex-col space-y-4 w-full py-2 px-1 rounded-sm smooth shadow-lg  bg-slate-200 dark:bg-slate-700 border border-sakrBlue-600">
                                    <div className="font-w-bold font-h3 clr-text-primary smooth ">
                                        {name}
                                    </div>

                                    <div className="clr-text-secondary smooth pr-1">
                                        {formatDescription(description)}
                                    </div>
                                </div>
                                <div className="font-smaller shrink-0 flex flex-col space-y-3">
                                    {subscriptions_count < 1 ? (
                                        <>
                                            {isPrepaid || isToAssignCourses ? (
                                                // <Button className="bg-gradient-to-r from-waterMelon-300 to-waterMelon-500 clr-white rounded-full px-3 py-1 flex-center-both">
                                                //     اشترك الآن !
                                                // </Button>
                                                <Link
                                                    to={`/course/${id}`}
                                                    className="border-2 border-waterMelon-500 rounded-full px-3 py-1 hover:bg-waterMelon-500 hover:text-white  smooth clr-text-primary"
                                                >
                                                    الاضطلاع علي المحاضرة
                                                </Link>
                                            ) : (
                                                <>
                                                    <Link
                                                        to={`/course/${id}`}
                                                        className="outline outline-offset-2 hover:outline-offset-0 outline-waterMelon-500 rounded-md px-3 py-1 hover:bg-waterMelon-500 hover:text-white  smooth clr-text-primary"
                                                    >
                                                        الدخول للكورس
                                                    </Link>{" "}
                                                    {price == 0 ? (
                                                        <div className="bg-gradient-to-r from-redMelon-500 to-purple-500 clr-white rounded-full px-3 py-1 flex-center-both">
                                                            كورس مجاني !
                                                        </div>
                                                    ) : (
                                                        <Link
                                                            to={`/course/${id}/subscribe/previous_invoices/`}
                                                            className="bg-gradient-to-r from-waterMelon-300 to-waterMelon-500 clr-white rounded-full px-3 py-1 flex-center-both"
                                                        >
                                                            اشترك الآن !
                                                        </Link>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <div className="bg-cyan-500 bg-opacity-10 border-cyan-500 border rounded-md py-4 px-3 font-w-bold text-cyan-500 text-center">
                                            انت {/* <br /> */}
                                            مشترك
                                            <br />
                                            {/* <br /> */} في هذا الكورس
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex-col flex space-y-3">
                                <div className="px-1">
                                    <BreakLine className="w-full fill-sakrBlue-500 opacity-50 dark:fill-redMelon-500 smooth" />
                                </div>
                                <div className="w-full opacity- font-w-bold font-smaller flex flex-col-reverse sm:flex-row space-y-4 space-y-reverse sm:space-y-0 sm:space-x-reverse sm:space-x-4 justify-between flex-center-y">
                                    {subscriptions_count < 1 ? (
                                        <>
                                            {isPrepaid ? (
                                                <Button onClick={() => onPrepaidClick(id, name)}>
                                                    اشترك في هذه المحاضرة !
                                                </Button>
                                            ) : isToAssignCourses ? (
                                                <Button
                                                    onClick={() => onAssignCourseClick(id, name)}
                                                >
                                                    اشترك في هذه المحاضرة !
                                                </Button>
                                            ) : price == 0 ? (
                                                <div>
                                                    <div className="bg-gradient-to-r from-redMelon-500 to-purple-500 text-slate-100 rounded-lg py-1 px-3 space-x-2 space-x-reverse">
                                                        كورس مجاني !
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    <div className="bg-waterMelon-500 text-slate-100 rounded-lg py-1 px-3 space-x-2 space-x-reverse">
                                                        <span className="bg-primary-container clr-text-primary smooth px-2 py-px rounded-md ">
                                                            {price}
                                                        </span>
                                                        <span>جنيهًا</span>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <Link
                                            to={`/course/${id}`}
                                            className="outline outline-offset-2 hover:outline-offset-0 outline-waterMelon-500 rounded-md px-3 py-1 bg-waterMelon-500 hover:bg-opacity-0 text-white  smooth hover:clr-text-primary"
                                        >
                                            الدخول للكورس
                                        </Link>
                                    )}
                                    <div className="flex sm:justify-end sm:items-start flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-1 items-center sm:space-x-reverse clr-text-secondary flex-wrap">
                                        <div className="flex flex-col space-y-2 shrink-0">
                                            <div className="flex justify-between space-x-1 space-x-reverse">
                                                <span className="flex-center-both">
                                                    {printFullDate(updated_at)}
                                                </span>
                                                <span className="font-normal flex-center-both transform -translate-y-px">
                                                    <Icon icon="icon-park-twotone:update-rotation" />
                                                </span>
                                            </div>
                                            <div className="flex  justify-between space-x-1 space-x-reverse">
                                                <span className="flex-center-both">
                                                    {printFullDate(created_at)}
                                                </span>
                                                <span className="font-normal flex-center-both transform -translate-y-px">
                                                    <Icon icon="ic:twotone-create-new-folder" />
                                                </span>
                                            </div>
                                        </div>
                                        {isNew && !subscriptions_count && (
                                            <span className="bg-yellow-600 rounded-lg px-2 pb-px clr-white shrink-0 mb-2">
                                                جديد !
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CourseCard;

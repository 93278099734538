import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../context/AuthContext";
import auth from "../services/authServices";
import http from "../services/httpServices";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { printUnit } from "../utils/ar";
import { adminPath } from "../services/defaultSettings";

const HasUnassignedSubscription = ({ renderOnlyRefetchBtn = false }) => {
    const location = useLocation();
    let { unassignedSubscriptions } = useContext(AuthContext);

    const [className, setClassName] = useState("");

    useEffect(() => {
        if (location.pathname.includes(adminPath)) {
            setClassName(" !hidden");
        } else {
            setClassName("");
        }
    }, [location.pathname]);

    return (
        <>
            {unassignedSubscriptions > 0 && !renderOnlyRefetchBtn ? (
                <Link
                    to="/available_to_assign_courses_store"
                    className={`fixed bottom-20 right-6 bg-slate-900 z-50 text-center p-2 rounded-md shadow-lg bg-opacity-90 font-smaller space-y-1 border border-slate-900 dark:border-slate-50 smooth clr-white ${className}`}
                >
                    <div>لديك من الرصيد</div>
                    <div
                        className={`flex-center-both bg-slate-500 rounded-md font-w-bold shadow-lg font-h3 underline px-3 py-1`}
                    >
                        {printUnit(unassignedSubscriptions, "اشتراك غير مخصص")}
                    </div>
                </Link>
            ) : (
                ""
            )}
        </>
    );
};

export default HasUnassignedSubscription;

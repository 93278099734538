import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../context/AuthContext";
import auth from "../services/authServices";
import http from "../services/httpServices";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { printUnit } from "../utils/ar";
import { adminPath, isUnassignedSubscription, prepaidInfoColor } from "../services/defaultSettings";

const HasPrepaid = ({ renderOnlyRefetchBtn = false }) => {
    const location = useLocation();
    const { prepaidCourses, setPrepaidCourses, token, unassignedSubscriptions } =
        useContext(AuthContext);

    let bgColorClassName = "bg-rose-500";
    let valueBgColorClassName = "bg-blue-500";
    switch (prepaidInfoColor) {
        case "yellow":
            bgColorClassName = "bg-yellow-500";
            valueBgColorClassName = "bg-blue-500";
            break;
        case "cyan":
            bgColorClassName = "bg-cyan-500";
            valueBgColorClassName = "bg-blue-500";
            break;
        case "emerald":
            bgColorClassName = "bg-emerald-500";
            valueBgColorClassName = "bg-blue-500";
            break;
        case "blue":
            bgColorClassName = "bg-blue-500";
            valueBgColorClassName = "bg-blue-500";
            break;
        default:
            break;
    }

    const [className, setClassName] = useState("");
    useEffect(() => {
        if (location.pathname.includes(adminPath)) {
            setClassName(" !hidden");
        } else {
            setClassName("");
        }
    }, [location.pathname]);

    return (
        <>
            {!renderOnlyRefetchBtn && prepaidCourses > 0 ? (
                <Link
                    to="/prepaid_store"
                    className={`fixed ${
                        unassignedSubscriptions > 0 && isUnassignedSubscription
                            ? "bottom-40"
                            : "bottom-6"
                    } right-6 ${bgColorClassName} z-50 text-center p-2 rounded-md shadow-lg bg-opacity-90 font-smaller space-y-1 border border-slate-900 dark:border-slate-50 smooth clr-white ${className}`}
                >
                    <div>لديك من الرصيد</div>
                    <div
                        className={`flex-center-both ${valueBgColorClassName} rounded-md font-w-bold shadow-lg font-h3 underline px-3 py-1`}
                    >
                        {printUnit(prepaidCourses, "محاضرة")}
                    </div>
                    <div>مسبقة الدفع</div>
                </Link>
            ) : (
                ""
            )}
        </>
    );
};

export default HasPrepaid;
